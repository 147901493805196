////// NAVIGATION

var nav = document.querySelector('.nav');
var hamburger = document.getElementById("hamburger");
hamburger.addEventListener("click", function(x) {
  hamburger.classList.toggle("change");
  nav.classList.toggle('nav-active');
});

////// ANIMATIONS
var controller = new ScrollMagic.Controller({});

var sections = document.querySelectorAll("section");
for (var i = 0; i < sections.length; i++) {
    new ScrollMagic.Scene({ triggerElement: sections[i] })
      .addTo(controller);
}

var headerTween = gsap.timeline()
  .to("#triangle", { opacity: 0, x: -200, y: -100, duration: 1 }, 0)
  .to("#circlebox", { opacity: 0, x: -200, y: -100, duration: 1 }, 0)
  .to("#donut", { opacity: 0, x: -200, y: 100, duration: 1 }, 0)
  .to("#circles", { opacity: 0, x: 200, y: -100, duration: 1 }, 0)
  .to("#box", { opacity: 0, x: 200, y: -100, duration: 1 }, 0)
  .to("#desktop-window", { opacity: 0, x: -200, y: 100, duration: 1 }, 0)
  .to("#desktop-back", { opacity: 0, x: 200, y: -100, duration: 1 }, 0)
  .to("#mouse", { opacity: 0, x: 200, y: 100, duration: 1 }, 0)
  .to("#smbox", { opacity: 0, x: 200, y: 100, duration: 1 }, 0)
  .to("#smbox2", { opacity: 0, x: -200, y: -100, duration: 1 }, 0)
  .to("#building", { opacity: 0, x: 200, y: 100, duration: 1 }, 0)
  .to("#db", { opacity: 0, x: -200, y: -100, duration: 1 }, 0)
  .to("#laptop", { opacity: 0, x: -200, y: 100, duration: 1 }, 0)
  .to("#user", { opacity: 0, x: -200, y: 100, duration: 1 }, 0)
  .to("#cog", { opacity: 0, x: -200, y: -100, duration: 1 }, 0)
  .to("#wire", { opacity: 0, x: -100, y: 50, duration: 1 }, 0)
  .to("#userbtn", { opacity: 0, x: -200, y: 100, duration: 1 }, 0);
var headerScene = new ScrollMagic.Scene({
    triggerElement: "#headerimg",
    //triggerHook: "onCenter",
    offset: 300,
    duration: 500,
    tweenChanges: true
})
  .setTween(headerTween)
  .addTo(controller);

var aboutTween = gsap.timeline()
  .from("#atriangle", { opacity: 0, x: -200, y: 100, duration: 1 }, 0)
  .from("#adonut", { opacity: 0, x: 200, y: 100, duration: 1 }, 0)
  .from("#acircleline", { opacity: 0, x: -200, y: -100, duration: 1 }, 0)
  .from("#arectangle", { opacity: 0, x: 200, y: -100, duration: 1 }, 0)
  .from("#asmbox", { opacity: 0, x: 200, y: 100, duration: 1 }, 0)
  .from("#abuilding", { opacity: 0, x: -200, y: 100, duration: 1 }, 0)
  .from("#anumbers", { opacity: 0, y: -200, duration: 1 }, 0)
  .from("#awires", { opacity: 0, x: 100, y: 50, duration: 1 }, 0)
  .from("#abox", { opacity: 0, x: -200, y: -100, duration: 1 }, 0)
  .from("#alaptop", { opacity: 0, x: 100, y: -50, duration: 1 }, 0)
  .from("#aphone", { opacity: 0, y: -50, duration: 1 }, 0)
  .from("#acircles", { opacity: 0, x: 200, y: -100, duration: 1 }, 0)
  .from("#aworkers", { opacity: 0, x: 200, y: 100, duration: 1 }, 0);
var aboutScene = new ScrollMagic.Scene({
    triggerElement: "#aboutimg",
    offset: -200,
    duration: 500,
    tweenChanges: true
})
  .setTween(aboutTween)
  .addTo(controller);

var clientsStagger = gsap.timeline()
  .from(".clientbox", {
      duration: .36,
      scale: .1,
      stagger: {
        amount: .36
      }
  });
var clientsScene = new ScrollMagic.Scene({
    triggerElement: "#clients",
    tweenChanges: true
})
  .setTween(clientsStagger)
  .addTo(controller);
